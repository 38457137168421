import React from 'react'
import { makeStyles } from '@mui/styles'
import { breakpoints, colors, padding } from '../db/assetsCss'
import illeterate from '../assets/illeterate.jpg'
import illIcon from '../assets/uneducated-icon.svg'
import blind from '../assets/blind.jpg'
import blindIcon from '../assets/blind-icon.svg'
import deaf from '../assets/deaf.jpg'
import deafIcon from '../assets/deaf-icon.svg'
import CardInclusive from '../components/CardInclusive'
import { Button } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  inclusiveCont: {
    padding: padding.desktop,
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 30,
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down(breakpoints.mobile)]: {
      padding: padding.mobile,
    },
    [theme.breakpoints.down(breakpoints.breakpoint3)]: {
      padding: padding.tablet,
    },
  },
  inclusiveHeading: {
    margin: 0,
    fontSize: 34,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    [theme.breakpoints.down(breakpoints.mobile)]: {
      fontSize: 30,
      textAlign: 'center',
    },
  },
  cardsCont: {
    width: '100%',
    display: 'flex',
    alignItems: 'stretch',
    gap: 100,
    justifyContent: 'center',
    [theme.breakpoints.down(breakpoints.breakpoint3)]: {
      flexDirection: 'column',
      gap: 35,
      alignItems: 'center',
    },
  },
  bgelement: {
    width: 340,
    height: 340,
    backgroundColor: `${colors.primary}`,
    position: 'absolute',
    filter: 'blur(15px)',
    borderRadius: '50%',
    zIndex: 0,
    opacity: 0.65,
    top: -170,
    right: -170,
  },
   heroHeading: {
    margin: 0,
    fontSize: 30,
    fontWeight: 'bold',
    [theme.breakpoints.down(breakpoints.mobile)]: {
      textAlign: 'center',
    },
  },
  heroSubHeading: {
    margin: 0,
    fontSize: 17,
    fontWeight: 400,
    marginTop: 10,
    [theme.breakpoints.down(breakpoints.mobile)]: {
      textAlign: 'center',
    },
  },
  heroCont:{
    width: '80%', textAlign: 'center', margin: '50px 0px',
    [theme.breakpoints.down(breakpoints.mobile)]: {
      width: '100%'
    },
  },
   CTACont: {
    // display: 'flex',
    alignItems: 'center',
    gap: 10,
    marginTop: 30,
    display: 'none',
    [theme.breakpoints.down(breakpoints.mobile)]: {
      width: '100%',
      flexDirection: 'column',
    display: 'flex',

    },
  },
  downloadButton: {
    fontSize: '14px !important',
    padding: '10px 40px !important',
    fontFamily: 'Poppins !important',
    color: `${colors.white} !important`,
    backgroundColor: `${colors.primary} !important`,
    border: `1px solid ${colors.primary} !important`,
    borderRadius: '5px !important',
    textTransform: 'capitalize !important',
    [theme.breakpoints.down(breakpoints.mobile)]: {
      width: '100% !important',
    },
  },
  contactButton: {
    fontSize: '14px !important',
    padding: '10px 40px !important',
    fontFamily: 'Poppins !important',
    color: `${colors.black} !important`,
    backgroundColor: `transparent !important`,
    border: `0px solid ${colors.primary} !important`,
    borderRadius: '5px !important',
    textTransform: 'capitalize !important',
    [theme.breakpoints.down(breakpoints.mobile)]: {
      width: '100% !important',
    },
  },
}))

const Inclusive = () => {
  const classes = useStyles()
  const cards = [
    {
      image: {
        src: illeterate,
        alt: 'analphabete',
      },
      icon: illIcon,
      text: 'For the Illeterate',
    },
    {
      image: {
        src: blind,
        alt: 'aveugle',
      },
      icon: blindIcon,
      text: 'For the Blind',
    },
    {
      image: {
        src: deaf,
        alt: 'sourd',
      },
      icon: deafIcon,
      text: 'For the Deaf',
    },
  ]
    const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer')
  }
  return (
    <div className={classes.inclusiveCont}>
      {/* <div className={classes.bgelement} /> */}
      <div className={classes.heroCont}>
         <h1 className={classes.heroHeading}>
            Strapp: Simplify your medication management.
          </h1>
          <p className={classes.heroSubHeading}>
            The app is currently in Beta. We're working on the next version with enhanced accessibility features for users with visual impairments and low literacy.
          </p>
              <div className={classes.CTACont}>
          <Button
            className={classes.downloadButton}
            variant="outlined"
            onClick={() =>
              openInNewTab(
                'https://play.google.com/store/apps/details?id=com.codeguess.strapp',
              )
            }
          >
            Download The App
          </Button>
          <Button
            className={classes.contactButton}
            variant="outlined"
            onClick={() => openInNewTab('mailto:contact@strapp.life')}
          >
            Contact Us
          </Button>
        </div>
      </div>
      <div className={classes.cardsCont}>
        {cards.map((card, index) => (
          <CardInclusive key={index} props={card} />
        ))}
      </div>
    </div>
  )
}

export default Inclusive

import React from 'react'
import { makeStyles } from '@mui/styles'
import { breakpoints, padding } from '../db/assetsCss'
import image1 from '../assets/image1.jpg'
import image2 from '../assets/image2.jpg'
import mainImage from '../assets/main.jpg'
import image3 from '../assets/image3.jpg'
import image4 from '../assets/image4.jpg'
import googlePlay from '../assets/googlePlay.png'

const useStyles = makeStyles((theme) => ({
  googlePlayCont: {
    width: '100%',
    boxSizing: 'border-box',
    padding: padding.desktop,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 30,
    [theme.breakpoints.down(breakpoints.breakpoint3)]: {
      padding: padding.tablet,
    },
    [theme.breakpoints.down(breakpoints.mobile)]: {
      padding: padding.mobile,
    },
  },
  googlePlayHeading: {
    margin: 0,
    fontSize: 34,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    [theme.breakpoints.down(breakpoints.mobile)]: {
      fontSize: 30,
      textAlign: 'center',
    },
  },
  insideCont: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 25,
    width: '100%',
  },
  pictureCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down(breakpoints.breakpoint3)]: {
      justifyContent: 'center',
    },
  },
  mainImage: {
    width: 275,
    borderRadius: 10,
    [theme.breakpoints.down(breakpoints.breakpoint1)]: {
      width: 250,
    },
    [theme.breakpoints.down(breakpoints.breakpoint3)]: {
      width: 275,
    },
  },
  image: {
    height: 400,
    borderRadius: 10,
    [theme.breakpoints.down(breakpoints.breakpoint1)]: {
      height: 350,
    },
    [theme.breakpoints.down(breakpoints.breakpoint3)]: {
      display: 'none',
    },
  },
  googlePlayCTA: {
    width: 275,
    borderRadius: 10,
    cursor: 'pointer',
    [theme.breakpoints.down(breakpoints.breakpoint1)]: {
      width: 250,
    },
    [theme.breakpoints.down(breakpoints.breakpoint3)]: {
      width: 275,
    },
  },
}))

const GooglePlay = () => {
  const classes = useStyles()
  const picrures = [
    {
      src: image1,
      class: classes.image,
    },
    {
      src: image2,
      class: classes.image,
    },
    {
      src: mainImage,
      class: classes.mainImage,
    },
    {
      src: image3,
      class: classes.image,
    },
    {
      src: image4,
      class: classes.image,
    },
  ]
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer')
  }
  return (
    <div className={classes.googlePlayCont}>
      <h2 className={classes.googlePlayHeading}>Strapp is on google play</h2>
      <div className={classes.insideCont}>
        <div className={classes.pictureCont}>
          {picrures.map((pic, index) => (
            <img
              src={pic.src}
              className={pic.class}
              alt="google play pic"
              key={index}
            />
          ))}
        </div>
        <img
          src={googlePlay}
          alt="google play download"
          className={classes.googlePlayCTA}
          onClick={() =>
            openInNewTab(
              'https://play.google.com/store/apps/details?id=com.codeguess.strapp',
            )
          }
          draggable={false}
        />
      </div>
    </div>
  )
}

export default GooglePlay

export const padding = {
  desktop: '45px 60px 45px 80px',
  tablet: '35px 40px',
  mobile: '15px 20px',
  navDesk: '10px 60px 10px 80px',
  navTab: '10px 40px',
  navMob: '10px 20px',
}

export const breakpoints = {
  breakpoint1: 1220,
  breakpoint2: 1156,
  breakpoint3: 1100,
  mobile: 660,
}

export const colors = {
  primary: '#00B1BB',
  white: '#ffffff',
  black: '#000000',
  border: 'rgba(108, 108, 108, 0.83)',
}

export const positions = {
  heroDesk: {
    bottom: -25,
    right: 60,
  },
}

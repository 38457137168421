import React from 'react'
import { makeStyles } from '@mui/styles'
import { breakpoints, padding } from '../db/assetsCss'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    padding: padding.desktop,
    boxSizing: 'border-box',
    [theme.breakpoints.down(breakpoints.breakpoint3)]: {
      padding: padding.tablet,
    },
    [theme.breakpoints.down(breakpoints.mobile)]: {
      padding: padding.mobile,
    },
  }
}))
const Policy = () => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <h1>PRIVACY POLICY</h1>
      <p>Last updated August 28, 2023</p>

      <p>
        This privacy notice for StrappLife (doing business as Strapp) ("we,"
        "us," or "our"), describes how and why we might collect, store, use,
        and/or share ("process") your information when you use our services
        ("Services"), such as when you:
      </p>

      <ul>
        <li>
          Download and use our mobile application (Strapp), or any other
          application of ours that links to this privacy notice
        </li>
        <li>
          Engage with us in other related ways, including any sales, marketing,
          or events
        </li>
      </ul>

      <p>
        Questions or concerns? Reading this privacy notice will help you
        understand your privacy rights and choices. If you do not agree with our
        policies and practices, please do not use our Services. If you still
        have any questions or concerns, please contact us at{' '}
        <a href="mailto:contact@strapp.life">contact@strapp.life</a>.
      </p>

      <h2>SUMMARY OF KEY POINTS</h2>
      <p>
        This summary provides key points from our privacy notice, but you can
        find out more details about any of these topics by clicking the link
        following each key point or by using our table of contents below to find
        the section you are looking for.
      </p>

      <ol>
        <li>
          <a href="#section1">WHAT INFORMATION DO WE COLLECT?</a>
        </li>
        <li>
          <a href="#section2">HOW DO WE PROCESS YOUR INFORMATION?</a>
        </li>
        <li>
          <a href="#section3">
            WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </a>
        </li>
        <li>
          <a href="#section4">HOW LONG DO WE KEEP YOUR INFORMATION?</a>
        </li>
        <li>
          <a href="#section5">HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
        </li>
        <li>
          <a href="#section6">WHAT ARE YOUR PRIVACY RIGHTS?</a>
        </li>
        <li>
          <a href="#section7">CONTROLS FOR DO-NOT-TRACK FEATURES</a>
        </li>
        <li>
          <a href="#section8">
            DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </a>
        </li>
        <li>
          <a href="#section9">DO WE MAKE UPDATES TO THIS NOTICE?</a>
        </li>
        <li>
          <a href="#section10">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
        </li>
        <li>
          <a href="#section11">
            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
          </a>
        </li>
      </ol>

      <h2 id="section1">1. WHAT INFORMATION DO WE COLLECT?</h2>

      <p>
        <strong>Personal information you disclose to us</strong>
      </p>
      <p>In Short: We collect personal information that you provide to us.</p>

      <p>
        We collect personal information that you voluntarily provide to us when
        you register on the Services, express an interest in obtaining
        information about us or our products and Services, when you participate
        in activities on the Services, or otherwise when you contact us.
      </p>

      <p>
        <strong>Personal Information Provided by You.</strong> The personal
        information that we collect depends on the context of your interactions
        with us and the Services, the choices you make, and the products and
        features you use. The personal information we collect may include the
        following:
      </p>
      <ul>
        <li>Names</li>
        <li>Phone numbers</li>
        <li>Email addresses</li>
        <li>Passwords</li>
        <li>Blood type</li>
        <li>Date of birth</li>
        <li>Disease</li>
        <li>Medical prescriptions</li>
        <li>City</li>
      </ul>

      <p>
        <strong>Sensitive Information.</strong> When necessary, with your
        consent or as otherwise permitted by applicable law, we process the
        following categories of sensitive information:
      </p>
      <ul>
        <li>Health data</li>
      </ul>

      <p>
        <strong>Application Data.</strong> If you use our application(s), we
        also may collect the following information if you choose to provide us
        with access or permission:
      </p>
      <ul>
        <li>
          Mobile Device Access. We may request access or permission to certain
          features from your mobile device, including your mobile device's
          reminders, and other features. If you wish to change our access or
          permissions, you may do so in your device's settings.
        </li>
        <li>
          Push Notifications. We may request to send you push notifications
          regarding your account or certain features of the application(s). If
          you wish to opt out from receiving these types of communications, you
          may turn them off in your device's settings.
        </li>
      </ul>
      <p>
        This information is primarily needed to maintain the security and
        operation of our application(s), for troubleshooting, and for our
        internal analytics and reporting purposes. All personal information that
        you provide to us must be true, complete, and accurate, and you must
        notify us of any changes to such personal information. Information
        automatically collected In Short: Some information — such as your
        Internet Protocol (IP) address and/or browser and device characteristics
        — is collected automatically when you visit our Services. We
        automatically collect certain information when you visit, use, or
        navigate the Services. This information does not reveal your specific
        identity (like your name or contact information) but may include device
        and usage information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our Services, and other technical information. This information is
        primarily needed to maintain the security and operation of our Services,
        and for our internal analytics and reporting purposes. <br />
        The information we collect includes: <br />
        Log and Usage Data. Log and usage data is service-related, diagnostic,
        usage, and performance information our servers automatically collect
        when you access or use our Services and which we record in log files.
        Depending on how you interact with us, this log data may include your IP
        address, device information, browser type, and settings and information
        about your activity in the Services (such as the date/time stamps
        associated with your usage, pages and files viewed, searches, and other
        actions you take such as which features you use), device event
        information (such as system activity, error reports (sometimes called
        "crash dumps" ), and hardware settings).
      </p>
      <h2 id="section2">2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
      <p>
        In Short: We process your information to provide, improve, and
        administer our Services, communicate with you, for security and fraud
        prevention, and to comply with law. We may also process your information
        for other purposes with your consent.
        <br />
        We process your personal information for a variety of reasons, depending
        on how you interact with our Services, including: To facilitate account
        creation and authentication and otherwise manage user accounts. We may
        process your information so you can create and log in to your account,
        as well as keep your account in working order.
        <br />
        To evaluate and improve our Services, products, marketing, and your
        experience. We may process your information when we believe it is
        necessary to identify usage trends, determine the effectiveness of our
        promotional campaigns, and to evaluate and improve our Services,
        products, marketing, and your experience. analyze the data. __________
      </p>

      <h2 id="section3">
        3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
      </h2>

      <p>
        In Short: We may share information in specific situations described in
        this section and/or with the following categories of third parties.
        <br />
        Vendors, Consultants, and Other Third-Party Service Providers. We may
        share your data with third-party vendors, service providers,
        contractors, or agents ("third parties") who perform services for us or
        on our behalf and require access to such information to do that work.
        The categories of third parties we may share personal information with
        are as follows: Data Analytics Services
        <br />
        We also may need to share your personal information in the following
        situations: Business Transfers. We may share or transfer your
        information in connection with, or during negotiations of, any merger,
        sale of company assets, financing, or acquisition of all or a portion of
        our business to another company.
        <br />
        Affiliates. We may share your information with our affiliates, in which
        case we will require those affiliates to honor this privacy notice.
        Affiliates include our parent company and any subsidiaries, joint
        venture partners, or other companies that we control or that are under
        common control with us.
        <br />
        Business Partners. We may share your information with our business
        partners to offer you certain products, services, or promotions.
        <br />
        Offer Wall. Our application(s) may display a third-party hosted "offer
        wall." Such an offer wall allows third-party advertisers to offer
        virtual currency, gifts, or other items to users in return for the
        acceptance and completion of an advertisement offer. Such an offer wall
        may appear in our application(s) and be displayed to you based on
        certain data, such as your geographic area or demographic information.
        When you click on an offer wall, you will be brought to an external
        website belonging to other persons and will leave our application(s). A
        unique identifier, such as your user ID, will be shared with the offer
        wall provider in order to prevent fraud and properly credit your account
        with the relevant reward.
      </p>

      <h2 id="section4">4. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>

      <p>
        In Short: We keep your information for as long as necessary to fulfill
        the purposes outlined in this privacy notice unless otherwise required
        by law.
        <br />
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting, or other legal requirements). No purpose in this notice will
        require us keeping your personal information for longer than the period
        of time in which users have an account with us.
        <br />
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymize such
        information, or, if this is not possible (for example, because your
        personal information has been stored in backup archives), then we will
        securely store your personal information and isolate it from any further
        processing until deletion is possible.
      </p>

      <h2 id="section5">5. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>

      <p>
        In Short: We aim to protect your personal information through a system
        of organizational and technical security measures.
        <br />
        We have implemented appropriate and reasonable technical and
        organizational security measures designed to protect the security of any
        personal information we process. However, despite our safeguards and
        efforts to secure your information, no electronic transmission over the
        Internet or information storage technology can be guaranteed to be 100%
        secure, so we cannot promise or guarantee that hackers, cybercriminals,
        or other unauthorized third parties will not be able to defeat our
        security and improperly collect, access, steal, or modify your
        information. Although we will do our best to protect your personal
        information, transmission of personal information to and from our
        Services is at your own risk. You should only access the Services within
        a secure environment.
      </p>

      <h2 id="section6">6. WHAT ARE YOUR PRIVACY RIGHTS?</h2>

      <p>
        In Short: You may review, change, or terminate your account at any time.
        <br />
        If you are located in the EEA or UK and you believe we are unlawfully
        processing your personal information, you also have the right to
        complain to your Member State data protection authority or UK data
        protection authority.
        <br />
        If you are located in Switzerland, you may contact the Federal Data
        Protection and Information Commissioner.
        <br />
        Withdrawing your consent: If we are relying on your consent to process
        your personal information, which may be express and/or implied consent
        depending on the applicable law, you have the right to withdraw your
        consent at any time. You can withdraw your consent at any time by
        contacting us by using the contact details provided in the section "HOW
        CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
        <br />
        However, please note that this will not affect the lawfulness of the
        processing before its withdrawal nor, when applicable law allows, will
        it affect the processing of your personal information conducted in
        reliance on lawful processing grounds other than consent.
        <br />
        <strong>Account Information</strong>
        <br />
        If you would at any time like to review or change the information in
        your account or terminate your account, you can:
        <ul>
          <li>Log in to your account settings and update your user account.</li>
          <li>
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our legal terms and/or comply with applicable legal
            requirements.
          </li>
        </ul>
        If you have questions or comments about your privacy rights, you may
        email us at contact@strapp.life.
      </p>

      <h2 id="section7">7. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>

      <p>
        Most web browsers and some mobile operating systems and mobile
        applications include a Do-Not-Track ("DNT") feature or setting you can
        activate to signal your privacy preference not to have data about your
        online browsing activities monitored and collected. At this stage no
        uniform technology standard for recognizing and implementing DNT signals
        has been finalized. As such, we do not currently respond to DNT browser
        signals or any other mechanism that automatically communicates your
        choice not to be tracked online. If a standard for online tracking is
        adopted that we must follow in the future, we will inform you about that
        practice in a revised version of this privacy notice.
      </p>

      <h2 id="section8">
        8. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
      </h2>

      <p>
        In Short: Yes, if you are a resident of California, you are granted
        specific rights regarding access to your personal information.
        <br />
        California Civil Code Section 1798.83, also known as the "Shine The
        Light" law, permits our users who are California residents to request
        and obtain from us, once a year and free of charge, information about
        categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
        <br />
        If you are under 18 years of age, reside in California, and have a
        registered account with Services, you have the right to request removal
        of unwanted data that you publicly post on the Services. To request
        removal of such data, please contact us using the contact information
        provided below and include the email address associated with your
        account and a statement that you reside in California. We will make sure
        the data is not publicly displayed on the Services, but please be aware
        that the data may not be completely or comprehensively removed from all
        our systems (e.g., backups, etc.).
      </p>

      <h2 id="section9">9. DO WE MAKE UPDATES TO THIS NOTICE?</h2>

      <p>
        In Short: Yes, we will update this notice as necessary to stay compliant
        with relevant laws.
        <br />
        We may update this privacy notice from time to time. The updated version
        will be indicated by an updated "Revised" date and the updated version
        will be effective as soon as it is accessible. If we make material
        changes to this privacy notice, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a
        notification. We encourage you to review this privacy notice frequently
        to be informed of how we are protecting your information.
      </p>

      <h2 id="section10">10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>

      <p>
        If you have questions or comments about this notice, you may email us at
        contact@strapp.life or contact us by post at:
        <br />
        StrappLife <br />
        1 Rue M. Ali Boumendjel, 16000 Alger Centre <br />
        Algiers, Algiers 16000 <br />
        Algeria <br />
      </p>

      <h2 id="section11">
        11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </h2>

      <p>
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it. To request to review, update, or delete
        your personal information, please fill out and submit a data subject
        access request.
      </p>
    </div>
  )
}

export default Policy

import React from 'react'
import { makeStyles } from '@mui/styles'
import { breakpoints, padding } from '../db/assetsCss'
import incubme from '../assets/incubme.png'
import canneBlanche from '../assets/canneBlanche.png'
import rouiba from '../assets/rouiba.png'
import SGA from '../assets/societeGeneral.png'
import ONEA from '../assets/offile national.jpg'
import BioPharm from '../assets/biopharm.jpg'

const useStyles = makeStyles((theme) => ({
  partnersCont: {
    padding: padding.desktop,
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 30,
    [theme.breakpoints.down(breakpoints.breakpoint3)]: {
      padding: padding.tablet,
    },
    [theme.breakpoints.down(breakpoints.mobile)]: {
      padding: padding.mobile,
    },
  },
  partnersHeading: {
    margin: 0,
    fontSize: 34,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    [theme.breakpoints.down(breakpoints.mobile)]: {
      fontSize: 30,
      textAlign: 'center',
    },
  },
  logosCont: {
    display: 'flex',
    alignItems: 'center',
    rowGap: 20,
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
  },
  logo: {
    width: 200,
    height: 100,
    objectFit: 'contain',
    margin: '0px 20px',
  },
}))

const Partners = () => {
  const classes = useStyles()
  const partners = [
    {
      src: SGA,
      alt: 'logo SOCIÉTÉ GÉNÉRALE ALGÉRIE',
      link: 'https://societegenerale.dz/',
    },
    {
      src: canneBlanche,
      alt: 'logo canne blanche',
      link: '',
    },
    {
      src: incubme,
      alt: 'logo incubme',
      link: 'https://incubme.com/',
    },
    {
      src: ONEA,
      alt: 'logo ONEA',
      link: '',
    },
    {
      src: BioPharm,
      alt: 'logo BioPharm',
      link: '',
    },
    {
      src: rouiba,
      alt: 'logo rouiba',
      link: '',
    },
  ]
  return (
    <div className={classes.partnersCont}>
      <h2 className={classes.partnersHeading}>Our Partners</h2>
      <div className={classes.logosCont}>
        {partners.map((partner, index) => (
          <img
            src={partner.src}
            alt={partner.alt}
            key={index}
            className={classes.logo}
            style={partner.link === '' ? {} : { cursor: 'pointer' }}
            onClick={() => {
              if (partner.link !== '') {
                window.open(partner.link, '_blank', 'noreferrer')
              }
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default Partners

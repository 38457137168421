import React from 'react'
// import Hero from '../sections/Hero'
import Inclusive from '../sections/Inclusive'
import GooglePlay from '../sections/GooglePlay'
import Partners from '../sections/Partners'


const Landing = () => {
  return (
    <div style={{ width: '100%', minHeight: '100vh' }}>
        {/* <Hero /> */}
        <Inclusive />
        <GooglePlay />
        <Partners />
      </div>
  )
}

export default Landing
